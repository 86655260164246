/* eslint jsx-a11y/label-has-for:0 */
import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Layout, Wrapper, Header, Button } from '../components'
import config from '../../config'
import Paper from '@material-ui/core/Paper'

const Content = styled.div`
  grid-column: 2;
  z-index: 9000;
  margin: 1rem 0;
  padding: 2rem;
  border-radius: 0.25rem;
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 40px 120px rgba(0, 0, 0, 0.75);

  h1 {
    padding: 0;
    margin: 0 0 1rem 0;
  }
`

const About = () => (
  <Layout>
    <Wrapper>
      <Helmet title={`About | ${config.siteTitle}`} />
      <Header>
        <Link to="/">{config.siteTitle}</Link>
      </Header>
      <Content>
        <h1>About</h1>
        <p>E-Prime (short for English-Prime or English Prime, sometimes denoted É or E′) is a version of the English language that excludes all forms of the verb to be, including all conjugations, contractions and archaic forms.</p>
          <p>Some scholars advocate using E-Prime as a device to clarify thinking and strengthen writing. A number of other scholars have criticized E-Prime's utility.</p>
        <h3>History</h3>
        <p>D. David Bourland Jr., who had studied under Alfred Korzybski, devised E-Prime as an addition to Korzybski's general semantics in the late 1940s. Bourland published the concept in a 1965 essay entitled "A Linguistic Note: Writing in E-Prime" (originally published in General Semantics Bulletin). The essay quickly generated controversy within the general semantics field, partly because practitioners of general semantics sometimes saw Bourland as attacking the verb 'to be' as such, and not just certain usages.</p>
          <p>Bourland collected and published three volumes of essays in support of his innovation. The first (1991), co-edited by Paul Dennithorne Johnston, bore the title: To Be or Not: An E-Prime Anthology. For the second, More E-Prime: To Be or Not II, published in 1994, he added a third editor, Jeremy Klein. Bourland and Johnston then edited a third book, E-Prime III: a third anthology, published in 1997.</p>
        &mdash; <small><em>via the Wikipedia article on E-Prime</em></small>
      </Content>
    </Wrapper>
  </Layout>
)

export default About
